import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { GlobalService } from 'src/app/services/global.service';
import { UserProvider } from '../modules/auth/providers/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    
    private userProvider: UserProvider,
     private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
     const tokenInfo = this.userProvider.getAccessToken(true);
      // return this.userProvider.getAccessToken().then(async token => {

      if (tokenInfo?.token) {
        // await this.userService.getCurrentUser().toPromise();
        return true;
      }
      // this.router.navigate([`/login`]);
      return false;
    // });
  }

}
