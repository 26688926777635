import { orderBy } from '../helpers/helper';

export interface ApiResponseError {

    errorMessage: string,
    propertyName: string
}

export type ResponseStatusType = "Ok" | "Invalid" | "Exception" | "InvalidRequest" | "NoData";
export enum ResponseStatus {
    Ok = "Ok",
    Invalid = "Invalid",
    Exception = "Exception",
    InvalidRequest = "InvalidRequest",
    NoData = "NoData"
}

export interface ApiResponse {
    errors: Array<ApiResponseError>,
    output: Array<any> | any
    status: ResponseStatus,
    message: string,
    pageSize: number,
    totalPages: number,
    totalCount: number,
    entityId?:string,
    currentPage?:number,
    srNoStart?:number,
    filters?:any,
    optionsData?:any
}

export interface DirectoryContact {
    id?: string;
    contactPerson: string;
    department?: string;
    designation?: string;
    ext: string;

}

export type ContactType = "Email" | "Phone" | "Mobile" | "Fax";

export const ContactTypes: { [contactType: string]: { type: ContactType, isRequired: boolean, isEmail: boolean } } = {
    "email": {
        type: "Email",
        "isRequired": true,
        "isEmail": true
    },
    "phone": {
        type: "Phone",
        "isRequired": false,
        "isEmail": false
    },
    "mobile": {
        type: "Mobile",
        "isRequired": false,
        "isEmail": false
    },
    "fax": {
        type: "Fax",
        "isRequired": false,
        "isEmail": false
    }
};

export interface ContactInfo {
    id?: string;
    contactType: ContactType;
    contactValue: string

}
export interface Contact {
    id?: string;
    Email: string;
    phone: string;
    mobile: string;
    fax: string;

}

export interface Address {
    //id: Number;
    addressLine1: string;
    addressLine2?: string;
    buildingOrUnit?: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
    isPrimary: boolean;
    //contactInfo: Contact;
}



export interface SortOrder {
    key: string, order: boolean | 'asc' | 'desc'
}

export interface SortOption {
    name: string,
    sortOrder: SortOrder[],
    solution: Function
}


export interface SortOptionKeyMap {
    [type: string]: SortOption
}


export interface SortingOptions { options: SortOptionKeyMap, sortOptionsList: Array<{ key: string, value: string }>, sort(list: Array<any>, key: string): Array<any> }


/**
 *generate sorting options dynamically
 *
 * for example
 * export const DOCUMENT_SORT_OPTIONS: { options: SortOptionKeyMap, sortOptionsList: any, sort(list: Array<any>, key: string): Array<any> } = {

    options: {
        'document-type-asc': {
            name: 'Document Type', sortOrder: [{ key: 'document.documentType', order: 'asc' }],
            solution: function (list: Array<any>) {
                return orderBy(list, this['sortOrder']);
            }
        },
        'expiry-date-asc': {
            name: 'Expiry Date (Asc)', sortOrder: [{ key: 'document.expiryDate', order: 'asc' }],
            solution: function (list: Array<any>) {
                return orderBy(list, this['sortOrder']);
            }
        },
        'expiry-date-desc': {
            name: 'Expiry Date (Desc)', sortOrder: [{ key: 'document.expiryDate', order: 'desc' }],
            solution: function (list: Array<any>) {
                return orderBy(list, this['sortOrder']);
            }
        }
    },

    get sortOptionsList(): { key: string, value: string }[] {
        return Object.keys(this.options).map((key) => ({ key, value: this.options[key].name }));
    },

    sort: function (list: Array<any>, key: string) {
        return this['options'][key].solution(list);
    }

}

* @param {Array<{ sortId: string, displayName: string, sortOrder: Array<SortOrder> }>} [options]
* @return {*}  {SortingOptions}
*/
export const GenerateSortingOptions = (options?: Array<{ sortId: string, displayName: string, sortOrder: Array<SortOrder> }>): SortingOptions => {

    let sortingOptions: SortingOptions = {

        options: {
            // 'document-type-asc': {
            //     name: 'Document Type', sortOrder: [{ key: 'document.documentType', order: 'asc' }],
            //     solution: function (list: Array<any>) {
            //         return orderBy(list, this['sortOrder']);
            //     }
            // },
            // 'expiry-date-asc': {
            //     name: 'Expiry Date (Asc)', sortOrder: [{ key: 'document.expiryDate', order: 'asc' }],
            //     solution: function (list: Array<any>) {
            //         return orderBy(list, this['sortOrder']);
            //     }
            // },
            // 'expiry-date-desc': {
            //     name: 'Expiry Date (Desc)', sortOrder: [{ key: 'document.expiryDate', order: 'desc' }],
            //     solution: function (list: Array<any>) {
            //         return orderBy(list, this['sortOrder']);
            //     }
            // }
        },

        get sortOptionsList(): { key: string, value: string }[] {
            return Object.keys(this.options).map((key) => ({ key, value: this.options[key].name }));
        },

        sort: function (list: Array<any>, key: string) {
            return this['options'][key].solution(list);
        }

    }
    //let _sortOrder = [];

    options && options.forEach(option => {
        sortingOptions.options[option.sortId] = {
            'name': option.displayName, 'sortOrder': option.sortOrder,
            solution: function (list: Array<any>) {
                return orderBy(list, this['sortOrder']);
            }
        }
    })

    //sortingOptions.
    return sortingOptions;
}

export interface DownloadButtonConfig {
    fullReportApi: string;
    shortReportApi: string;
    downloadParams: {};
    downloadFileName: string;
}
