import { HttpEvent, HttpEventType, HttpHeaderResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ComponentRef, Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController, PopoverController, IonicSafeString } from '@ionic/angular';
import { ApiResponse, ResponseStatus } from 'src/app/models/common.model';


export type AlertTypes = "Alert" | "Toast" | "Combine";
export const AlertType: { [key: string]: AlertTypes } = { "Alert": "Alert", "Toast": "Toast", "Combine": "Combine" };

export type AlertColorType = 'danger' | 'dark' | 'warning' | 'light' | 'success' | 'primary' | 'secondary';
export const AlertColor: { [key: string]: AlertColorType } = { 'Danger': 'danger', 'Dark': 'dark', 'Warning': 'warning', 'Light': 'light', 'Success': 'success', 'primary': 'primary', 'secondary': 'secondary' };
export type AlertPositionType = 'bottom' | 'top' | 'middle';
export const AlertPosition: { [key: string]: AlertPositionType } = { 'Bottom': 'bottom', 'Top': 'top', 'Middle': 'middle' };
type OperationType = 'Delete' | 'Update' | 'Save' | 'Edit' | 'Add' | 'Create' | 'Load' | 'Fetch' | 'Process' | 'Submit' | 'Register' | 'Sent' | 'Upload' | 'Login' | 'Logout';

@Injectable({
  providedIn: 'root'
})
export class AlertMsgProvider {

  constructor(
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private PopoverCtrl: PopoverController
  ) { }

  async toaster(message: string, color: AlertColorType = AlertColor.Dark, position: AlertPositionType = AlertPosition.Bottom) {
    setTimeout(async () => {
      var top = await this.loadingCtrl.getTop();
      if (top) {
        this.loadingCtrl.dismiss();
      }
    }, 1200)

    const toast = await this.toastCtrl.create({
      message: message,
      color: color,
      duration: 2000,
      position: position
    });
    await toast.present();
  }

  async alertMsg(title: string, message: string, response: any = null) {
    const alert = await this.alertCtrl.create({
      message: message,
      header: title,
      buttons: ['Ok']
    });
    await alert.present();
  }

  async SuccessAlert(title: string, subTitle: string, message: string, response: any = null) {
    setTimeout(async () => {
      var top = await this.loadingCtrl.getTop();
      if (top) {
        this.loadingCtrl.dismiss();
      }
    }, 1200)
    const alert = await this.alertCtrl.create({
      header: title,
      cssClass: 'alert-succ',
      subHeader: subTitle,
      message: message,
      buttons: ['Ok']
    });
    await alert.present();
  }

  async ErrorAlert(title: string, _subtitle: string, _message: string, response: any = {}) {
    let isApiError: boolean = false;
    if (response.error) {
      isApiError = true;
    }
    setTimeout(async () => {
      var top = await this.loadingCtrl.getTop();
      if (top) {
        this.loadingCtrl.dismiss();
      }
    }, 1200)
    let subTitle = _subtitle || "", message = _message || "";


    subTitle = response["status"] || "Error", message = message || response["message"] || "";
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: subTitle,
      message: message,
      cssClass: 'alert-err',
      buttons: ['Ok']
    });
    await alert.present();
  }

  async ResponseAlert(response: ApiResponse | HttpEvent<any> = null, recordType: string = "Record", operation: OperationType, alertType: AlertTypes = "Combine", successCallback: Function = (res: ApiResponse) => { }, errorCallback: Function = null, showSuccessMessage = true) {
    //return;
    var top = await this.loadingCtrl.getTop();
    if (response["type"] === HttpEventType.Sent) {
      if (top) top.message = "Uploading...";
      return;
    }
    else if (response["type"] === HttpEventType.DownloadProgress) {
      if (top) top.message = "Saving...";
      return;
    }
    else if (response["type"] === HttpEventType.UploadProgress) {
      const percentDone = Math.ceil(100 * response["loaded"] / response["total"]);
      if (top) top.message = `Uploading${percentDone}%...`;
      return;
    } else if (response instanceof HttpHeaderResponse) {

      if (top) top.message = "Receiving..."
      return;
    }
    else {
      response = response['body'] || response;
    }


    if (response["status"] == ResponseStatus.Ok) {

      setTimeout(async () => {
        var top = await this.loadingCtrl.getTop();
        if (top) {
          try {

            this.loadingCtrl.dismiss();
            (await this.loadingCtrl.getTop()).message
          }
          catch (ex) {

          }
        }
      }, 200)


      if (showSuccessMessage) {
        if (alertType == "Toast" || alertType == "Combine") {
          this.toaster(`${recordType} ${this.processedMessage(operation)} Successfully!`, "success", "bottom");
        }
        else {
          this.SuccessAlert("", `${this.processedMessage(operation)}`, `${recordType} ${this.processedMessage(operation)} Successfully!`);
        }
      }
      if (successCallback) successCallback(response);
    }
    else {
      this.ResponseErrorAlert(response, 'Combine', (er) => {
        errorCallback && errorCallback(response);
      });
    }
  }

  private processedMessage(operation: OperationType) {
    switch (operation) {

      case "Add":
      case "Load":
      case "Process":
      case "Fetch":
      case "Edit":
      case "Register":
      case "Upload":
        {
          return `${operation}ed`;
        }
      case "Update":
        {
          return "Updated";
        }
      case "Create":
        {
          return "Updated";
        }
      case "Delete":
        {
          return "Deleted";
        }
      case "Save":
        {
          return "Saved";
        }
      case "Submit":
        {
          return "Submitted";
        }
      case "Login":
        {
          return "Logged In";
        }
      case "Logout":
        {
          return "Logged Out";
        }
      default: {
        return operation;
      }
    }

  }

  async ResponseErrorAlert(response: any = {}, alertType: AlertTypes = "Combine", errorCallback: Function = null) {
    let isApiError: boolean = false;
    if (response.error) {
      isApiError = true;
    }
    setTimeout(async () => {
      var top = await this.loadingCtrl.getTop();
      if (top) {
        try {

          this.loadingCtrl.dismiss();
        }
        catch (ex) {

        }
      }
    }, 200)


    let subTitle = response["status"] == 400 ? "Validation Error" : response?.handledError?.message || "", message = response?.handledError?.description || "", title = "";
    subTitle = subTitle || response["status"] || "Error", message = message || response["message"] || "";

    if (!message && response?.errors) {
      for (const error in response?.errors) {
        if (Object.prototype.hasOwnProperty.call(response?.errors, error)) {
          // const element = response?.errors[error];
          let erMessage = "";
          if ((error || "").indexOf("$.") >= 0) {
            erMessage = "Invalid input for field " + (error || "").replace("$.", "");
          }
          else if (Array.isArray(response?.errors[error]))
            response?.errors[error].forEach(element => {
              erMessage += (erMessage ? "\n" : "") + element;
            });
          else if (!Array.isArray(response?.errors[error]) && typeof (response?.errors[error]) == "object") {

            erMessage += (erMessage ? "\n" : "") + response?.errors[error].errorMessage;
          }
          message = message + (message ? "\n" : "") + erMessage;
        }
      }
    }

    if (errorCallback) {
      errorCallback(response);
    }
    if (alertType == "Toast") {
      return this.toaster(subTitle, "danger", "top");
    }
    else {
      message = message.replace('\n', '<br/>');
      const alert = await this.alertCtrl.create({
        header: title,
        subHeader: subTitle,
        message: new IonicSafeString(message),
        cssClass: 'alert-err',
        buttons: ['Ok']
      });
      return await alert.present();
    }

  }

  ResponseErrorMessage(response: any = {}) {
    let isApiError: boolean = false;
    if (response?.error) {
      isApiError = true;
    }
    setTimeout(async () => {
      var top = await this.loadingCtrl.getTop();
      if (top) {
        try {

          this.loadingCtrl.dismiss();
        }
        catch (ex) {

        }
      }
    }, 200)


    let subTitle = response?.status == 400 ? "Validation Error" : '', message = response?.handledError?.description || "", title = "";


    subTitle = response?.handledError?.message || subTitle || response?.status || "", message = message || response?.message || "";

    if (!message && response?.errors) {
      for (const error in response?.errors) {
        if (Object.prototype.hasOwnProperty.call(response?.errors, error)) {
          // const element = response?.errors[error];
          let erMessage = "";
          if ((error || "").indexOf("$.") >= 0) {
            erMessage = "Invalid input for field " + (error || "").replace("$.", "");
          }
          else if (Array.isArray(response?.errors[error]))
            response?.errors[error].forEach(element => {
              erMessage += (erMessage ? "\n" : "") + element;
            });
          else if (!Array.isArray(response?.errors[error]) && typeof (response?.errors[error]) == "object") {

            erMessage += (erMessage ? "\n" : "") + response?.errors[error].errorMessage;
          }
          message = message + (message ? "\n" : "") + erMessage;
        }
      }
    }

    return { title, subTitle, message };
  }


  async alertWithSingleButton(header: string, message: string, buttontext: string, buttonHandler: (value: any) => boolean | void | { [key: string]: any; }) {
    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [
        {
          text: buttontext,
          handler: buttonHandler
        }]
    });

    await alert.present();
    return alert.onDidDismiss();
  }

  async alertWithButton(header: string, message: string, buttontext: string, buttonHandler: (value: any) => boolean | void | { [key: string]: any; }) {
    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [{
        text: 'Cancel',
      },
      {
        text: buttontext,
        handler: buttonHandler
      }]
    });

    await alert.present();
  }

  async loading(
    msg: string = "Please wait",
    duration: number = 0,
    spinner: "bubbles" | "circles" | "circular" | "crescent" | "dots" | "lines" | "lines-small" = 'bubbles') {
    const load = await this.loadingCtrl.create({
      message: msg,
      duration: duration,
      spinner: spinner
    });
    await load.present();
    return load;
  }

  async dismissLoading() {
    try {
      if (await this.loadingCtrl.getTop())
        this.loadingCtrl.dismiss();
    }
    catch (e) {

    }
  }

  async popover(ev: any, component: any, data: any) {
    const pop = await this.PopoverCtrl.create({
      component: component,
      event: ev,
      translucent: false,
      componentProps: data
    });

    await pop.present();
    return pop;
  }

  async Confirm(header, subHeader, message = '', isDanger = false) {
    const alert = await this.alertCtrl.create({
      header: header || 'confirm',
      subHeader: subHeader,
      message: message,
      cssClass: isDanger ? 'alert-err' : '',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => { }
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role;
  }
  async ConfirmExit() {
    return (await this.Confirm('Confirm Exit', '', 'Do you want to continue?', false)) == 'confirm';
  }
  async ConfirmUnSavedExit() {
    return (await this.Confirm('Unsaved Changes May Lose!', '', 'Do you want to continue?', true)) == 'confirm';
  }

  async ConfirmDelete(recordName: string) {
    return (await this.Confirm('Delete' + (recordName ? ' "' + recordName + '"' : ''), '', 'Do you want to continue?', false)) == 'confirm';
  }
  async ConfirmMessage(message, isDanger = false) {
    return (await this.Confirm('', 'Confirm', message, isDanger)) == 'confirm';

  }

}
