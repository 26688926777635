<ion-content>
  <ion-list *ngIf="notifications && notifications.length > 0 ; else notificationsEmpty">
    <ion-item *ngFor="let notifi of notifications">
      <div class="noti-div">
        <p class="subtitle">{{notifi.notificationText}}</p>
        <p class="date">{{notifi.createdDate | date: 'dd/MM/yyyy hh:mm a'}}</p>
      </div>
    </ion-item>
    <ion-item lines="none">
      <ion-label (click)="viewAll()" class="all hand">View All</ion-label>
    </ion-item>

  </ion-list>
  <ng-template #notificationsEmpty>
    <ion-item>
      <ion-card-content>
         Notification list is empty
      </ion-card-content>
    </ion-item>
  </ng-template>
</ion-content>
