import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../storage.service';
import { UserProvider } from 'src/app/modules/auth/providers/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private userProvider: UserProvider) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const tokenInfo = this.userProvider.getAccessToken(false);
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${tokenInfo?.token || ""}`,
        companyId: tokenInfo?.companyId || ""
      }
    });

    return next.handle(request);
  }
}