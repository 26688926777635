import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import {Router } from '@angular/router';
import { UserProvider } from '../../providers/user.service';
import { AuthService } from '../../services/auth.service';
import { ApiResponse } from 'src/app/models/common.model';
//import {UserService } from '../../../providers/user/user.service';
//import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-header-user-popover',
  templateUrl: './header-user-popover.component.html',
  styleUrls: ['./header-user-popover.component.scss'],
})
export class HeaderUserPopoverComponent implements OnInit {
  userData: any;

  constructor(private router: Router,public userProvider:UserProvider,
    private popover: PopoverController,private auth:AuthService
    ) { }

  async ngOnInit() {
    (await this.userProvider.subscribeUserInfo()).subscribe((data) => {
      this.userData = data;
    });
    // this.userData=this.userProvider.userData
    // if(!this.userData){
    //   this.auth.getLoggedInUserProfile().subscribe((res:ApiResponse)=>{
    //     this.userData=res.output
    //     this.userProvider.userData=res.output
    //   })
    // }
  }

  logout(){
    this.popover.dismiss();
    this.userProvider.logout();
  }

  gotoUserProfile(){
    this.popover.dismiss();
    this.router.navigateByUrl('/user-profile');
  }

  gotoChangePassword(){
    this.popover.dismiss();
    this.router.navigateByUrl('/change-password');
  }
}
