<ion-app>
  <ion-router-outlet [animated]="false"></ion-router-outlet>
</ion-app>

<!-- 

<ion-app>

  

  <ion-split-pane when="xl" contentId="main">
    <ion-content class="bg-white">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title class="ion-text-center">
            Menu
          </ion-title>
        </ion-toolbar>
      </ion-header>
        <ion-list>
          <ion-item [ngClass]="{'active': isActive('/service-list')}" class="hand"  (click)="gotoPage('service-list')" >
            <ion-icon name="build-outline" slot="start"></ion-icon>
            <ion-label>
                Service
            </ion-label>
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/service-category')}" class="hand" (click)="gotoPage('service-category')" >
            <ion-icon name="build-outline" slot="start"></ion-icon>
            <ion-label>
                Service Category
            </ion-label> 
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/product-list') || isActive('/add-product') || isActive('/view-product')}" 
            (click)="gotoPage('product-list')" class="hand">
            <ion-icon name="server-outline" slot="start"></ion-icon>
            <ion-label>
                Product
            </ion-label>
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/product-category-list') || isActive('/add-product-category')}" 
            (click)="gotoPage('product-category-list')" class="hand">
            <ion-icon name="server-outline" slot="start"></ion-icon>
            <ion-label>
                Product Category
            </ion-label>
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/purchase-order-list') || isActive('/add-purchase-order') || isActive('/edit-purchase-order')}" 
            (click)="gotoPage('purchase-order-list')" class="hand">
            <ion-icon name="cash-outline" slot="start"></ion-icon>
            <ion-label>
                Purchase Orders
            </ion-label>
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/receiving-order-list') || isActive('/add-receiving-order')}" 
            (click)="gotoPage('receiving-order-list')" class="hand">
            <ion-icon name="reader-outline" slot="start"></ion-icon>
            <ion-label>
                Receiving Orders
            </ion-label>
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/back-order-list') || isActive('/add-back-order')}" 
            (click)="gotoPage('back-order-list')" class="hand">
            <ion-icon name="receipt-outline" slot="start"></ion-icon>
            <ion-label>
                Back Orders
            </ion-label>
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/supplier-list') || isActive('/add-supplier')}" 
            (click)="gotoPage('supplier-list')" class="hand">
            <ion-icon name="people-outline" slot="start"></ion-icon>
            <ion-label>
              Provider
            </ion-label>
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/product-supplier-list')}" (click)="gotoPage('product-supplier-list')" class="hand">
            <ion-icon name="people-outline" slot="start"></ion-icon>
            <ion-label>
                Product Provider
            </ion-label>
          </ion-item>

          <ion-item [ngClass]="{'active': isActive('/sales-orders')}" (click)="gotoPage('sales-orders')" class="hand">
            <ion-icon name="cart-outline" slot="start"></ion-icon>
            <ion-label>
                Sales Orders
            </ion-label>
          </ion-item>
          <ion-item (click)="gotoPage('product-category-list')" class="hand">
            <ion-icon name="settings-outline" slot="start"></ion-icon>
            <ion-label>
                Settings
            </ion-label>
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/user-profile')}" (click)="gotoPage('user-profile')" class="hand">
            <ion-icon name="person-outline" slot="start"></ion-icon>
            <ion-label>
                User Profile
            </ion-label>
          </ion-item>
          <ion-item [ngClass]="{'active': isActive('/change-password')}" (click)="gotoPage('change-password')" class="hand">
            <ion-icon name="key-outline" slot="start"></ion-icon>
            <ion-label>
              Change Password
            </ion-label>
          </ion-item>
          <ion-item (click)="gotoPage('login')" class="hand">
            <ion-icon name="log-out-outline" slot="start"></ion-icon>
            <ion-label>
                Sign Out
            </ion-label>
          </ion-item>
        </ion-list>
  
    </ion-content>
  
    <div class="ion-page" id="main">
      <ion-header>
        <ion-toolbar color="#FFFFFF" color="primary">
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button> 
          </ion-buttons>
          <ion-title>
            <img style="vertical-align:middle ;border-radius: 4px;" height="40" width="40" [src]="companyInfo?.profilePic"
              alt="" />
            &nbsp;
            <p style="display:inline-block ;vertical-align:middle ;margin: 1px;">{{companyInfo?.companyName}}</p>
          </ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="openNotification($event)">
              <ion-icon slot="icon-only" name="notifications-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="userPopover($event)">
              <ion-icon slot="icon-only" name="person-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-router-outlet [animated]="false"></ion-router-outlet>

    </div>
  </ion-split-pane>
  
  </ion-app>
  
  
   -->
