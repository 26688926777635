import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserProvider } from './modules/auth/providers/user.service';
import { AuthService } from './modules/auth/services/auth.service';
import { PopoverController } from '@ionic/angular';
import { ApiResponse } from './models/common.model';
import { NotificationPopoverComponent } from './modules/auth/components/notification-popover/notification-popover.component';
import { HeaderUserPopoverComponent } from './modules/auth/components/header-user-popover/header-user-popover.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  userData: any;
  profileData: any;
  pageTitle: any;
  profile: any;
  notifications: any;
  companyInfo: any;
  constructor(
    public userProvider: UserProvider,
    private authService: AuthService,
    public popoverCtrl: PopoverController,
    private router: Router
  ) { }

  async ngOnInit() {

    this.userProvider.startNewSession();

  }


  async openNotification(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: NotificationPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: { 'notifications': this.notifications }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }

  async userPopover(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: HeaderUserPopoverComponent,
      componentProps: {},
      event: ev
    });
    await popover.present();
  }



  isActive(page) {
    return this.router.url.split('?')[0] == page
  }

  gotoPage(page) {
    this.router.navigate([page])
  }
}
