<ion-content>
  <ion-list>
    <ion-list-header>
      User info
    </ion-list-header>

    <ion-item>
      <ion-avatar>
        <img src="assets/user-grey.png">
      </ion-avatar>
      <ion-label>
        <h3>{{userData?.firstName}}  {{userData?.lastName}}</h3>
        <p>{{userData?.email}}</p>
      </ion-label>
    </ion-item>
    <ion-item button routerLink="/profile" (click)="gotoUserProfile()">
      <ion-label>
        <h3>User Profile</h3>
      </ion-label>
      <ion-icon name="person-outline" slot="end"></ion-icon>
    </ion-item>

    <ion-item button routerLink="/change-password" (click)="gotoChangePassword()">
      <ion-label>
        <h3>Change Password</h3>
      </ion-label>
      <ion-icon name="key-outline" slot="end"></ion-icon>
    </ion-item>

    <ion-item button (click)="logout()">
      <ion-icon name="log-out-outline" slot="end" color="danger"></ion-icon>
      <ion-text color="danger">
        Logout
      </ion-text>
    </ion-item>
  </ion-list>
</ion-content>
