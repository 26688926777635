import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpService {

  apiBaseUrl: string = environment.apiBaseUrl;
  user: any;
  constructor(private http: HttpClient, public router: Router) {
    
  }

  post(method: string, body: any, params: any = {}) {
    const url = this.apiBaseUrl + method;
    return this.http.post(url, body,{});
  }

  postWithParams(method: string, body: any, params: any = {}) {
    const url = this.apiBaseUrl + method;
    return this.http.post(url, body,{
      params:params
    });
  }

  get(method: string, params: any) {
    const url = this.apiBaseUrl + method;
    return this.http.get(url, {
   
    });
  }

  postWithoutToken(method: string, body: any, params: any = {}) {
    const url = this.apiBaseUrl + method;
    return this.http.post(url, body,{
    });
  }

  postfile(method: string, body: any, params: any = {}) {
    const url = this.apiBaseUrl + method;
    return this.http.post(url, body,{});
  }


}
