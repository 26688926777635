import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  constructor() { }

  localStore(key, value) {
    localStorage.setItem(key, value ? typeof value == 'object' ? JSON.stringify(value) : value : '');
  }

  getLocal(key) {
    return localStorage.getItem(key);
  }

  removeLocal(key) {
    return localStorage.removeItem(key);
  }

  clear() {
    return localStorage.clear();
  }
}
